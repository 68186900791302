// $.fn.cycle.log = $.noop;

// function setCookie(key, value, expiry) {
//   const expires = new Date();
//   expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
//   document.cookie =
//     key +
//     "=" +
//     value +
//     ";path=/UniversidaddeLASALLE/" +
//     ";expires=" +
//     expires.toUTCString();
// }

// function getCookie(key) {
//   const keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
//   return keyValue ? keyValue[2] : null;
// }

// function eraseCookie(key) {
//   const keyValue = getCookie(key);
//   setCookie(key, keyValue, "-1");
// }

// function changeTheme() {
//   setCookie("colorCookie", "theme--dark");
//   setCookie("btnActive", "active__dark");
// }

// function removeTheme() {
//   // console.log("theme");
//   eraseCookie("colorCookie");
//   eraseCookie("btnActive");
//   // setCookie('colorCookie', 'theme');
//   // setCookie('btnActive', 'inactive__dark');
// }

// $("body#control").addClass(getCookie("colorCookie"));
// $(".dark__theme").attr("id", getCookie("btnActive"));

// $("#active__dark").click(function () {
//   $("#control").removeClass("theme--dark");
//   removeTheme();
//   //console.log('valor ifnot', getCookie('colorCookie'))
//   $("body#control").addClass(getCookie("colorCookie"));
//   $(".dark__theme").attr("id", getCookie("btnActive"));
//   location.reload();
// });

// $("#inactive__dark").click(function () {
//   changeTheme();
//   //console.log('valor ifnot', getCookie('colorCookie'))
//   $("body#control").addClass(getCookie("colorCookie"));
//   $(".dark__theme").attr("id", getCookie("btnActive"));
//   location.reload();
// });

$(".active__dark").click(function () {
  $(this).toggleClass("is-active");
  $("#control").toggleClass("theme--dark");
});

$(".active__font").click(function () {
  $(this).toggleClass("is-active");
  $("#control").toggleClass("fontx--x2", "");
});

$(".backfrond").click(function () {
  $("section.video_section .box_action_copy").toggleClass("is-inactive");
  $("section.video_section .box_action_act").toggleClass("is-inactive");
});

// $('.user_select').modaal();

// acordeon material
// $(function () {
//   $(".mdl-accordion__content").each(function () {
//     let content = $(this);
//     content.css("margin-top", -content.height());
//   });
//   $(document.body).on("click", ".mdl-accordion__button", function () {
//     $(this).parent(".mdl-accordion").toggleClass("mdl-accordion--opened");
//   });
// });

// menu header

$("#btn_menu_m").click(function () {
  $("#draw").toggleClass("is-active");
  $(".bg_black").addClass("is-active");
});

$("#close_draw").click(function () {
  $("#draw").toggleClass("is-active");
  $(".bg_black").removeClass("is-active");
});

$(".bg_black").click(function () {
  $("#draw").removeClass("is-active");
  $("#draw-fast").removeClass("is-active");
  $("#draw-footer").removeClass("is-active");
  $(".bg_black").removeClass("is-active");
});

// menu rapido

$(".btn_footer").click(function () {
  $("#draw-footer").toggleClass("is-active");
  $(".bg_black").addClass("is-active");
});

$(".close_footer").click(function () {
  $("#draw").removeClass("is-active");
  $("#draw-fast").removeClass("is-active");
  $("#draw-footer").toggleClass("is-active");
  $(".bg_black").removeClass("is-active");
});

$(".btn_fast").click(function () {
  $("#draw-fast").toggleClass("is-active");
  $(".bg_black").addClass("is-active");
});

$("#close_draw-fast").click(function () {
  $("#draw-fast").toggleClass("is-active");
  $(".bg_black").removeClass("is-active");
});
